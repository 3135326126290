<template>
  <div class="shell-box">
    <el-breadcrumb separator="/"
      class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/routerPolicyManagement/policyHosting' }">保单托管</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $route.query.detailData ? '编辑' : '添加' }}托管单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyForm ref="policyForm"
        :detailData="policyDetail"
        :permissions="policyDetailPermissions" />
    </div>
    <div class="operating-box">
      <el-button size="mini"
        @click="handleBack">返回</el-button>
      <el-button size="mini"
        type="primary"
        @click="handleReset"
        plain>重置</el-button>
      <el-button size="mini"
        type="warning"
        plain
        @click="showUpload = true">上传附件</el-button>
      <el-button size="mini"
        type="primary"
        @click="handlePolicyUpdate">提交</el-button>
    </div>
    <DragMultipleUpload :show.sync="showUpload"
      :dataEcho="policyDetail.attachments.bdtg" :fileSzie="10"
      :canDeletOld="false"
      @submitData="changeAttachment"></DragMultipleUpload>
  </div>
</template>

<script>
import policyForm from "@/views/policyManagement/components/Hosting/form.vue";
import { trusteeshipDetails, trusteeshipSubject, getPolicyDetail } from "@/api/policy";
import DragMultipleUpload from '@/views/policyManagement/components/Hosting/attachment.vue';
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { policyForm, DragMultipleUpload },
  data() {
    return {
      policyDetail: {
        attachments: {
          bdtg: []
        }
      },
      originalPolicyDetail: null,
      showUpload: false,
      dataEcho: {},
      attachments: {
        bdtg: []
      },
      policyDetailPermissions: {
        agentName: dataPermissions('M20-Edit', 'agentName'),
        // "desc": "代理人", +
        applicantMode: dataPermissions('M20-Edit', 'applicantMode'),
        // "desc": "投保方式", +
        lastPolicyNo: dataPermissions('M20-Edit', 'lastPolicyNo'),
        // "desc": "上一年保单号", +
        endDate: dataPermissions('M20-Edit', 'endDate'),
        // "desc": "到期时间", +
        city: dataPermissions('M20-Edit', 'city'),
        // "desc": "所属地区", +
        policyNo: dataPermissions('M20-Edit', 'policyNo'),
        // "desc": "保单号", +
        province: dataPermissions('M20-Edit', 'province'),
        // "desc": "所属省份", +
        applicantType: dataPermissions('M20-Edit', 'applicantType'),
        // "desc": "投保类型", +
        acceptCompanyList: dataPermissions('M20-Edit', 'acceptCompanyList'),
        // "desc": "承保公司", +
        extensionClause: dataPermissions('M20-Edit', 'extensionClause'),
        // "desc": "免费扩展条款",+
        specialAgreement: dataPermissions('M20-Edit', 'specialAgreement'),
        // "desc": "特别约定",+
        applicantStore: dataPermissions('M20-Edit', 'applicantStore'),
        // "desc": "投保门店",+
        absoluteDeductible3: dataPermissions('M20-Edit', 'absoluteDeductible3'),
        // "desc": "未扩展不计免赔",+
        absoluteDeductible2: dataPermissions('M20-Edit', 'absoluteDeductible2'),
        // "desc": "扩展不计免赔2",+
        salesmanId: dataPermissions('M20-Edit', 'salesmanId'),
        // "desc": "业务员",+
        agentNo: dataPermissions('M20-Edit', 'agentNo'),
        // "desc": "代理人编码",+
        beneficiaryList: dataPermissions('M20-Edit', 'beneficiaryList'),
        // "desc": "受益人",+
        policyHolderList: dataPermissions('M20-Edit', 'policyHolderList'),
        // "desc": "投保人",+
        totalPremium: dataPermissions('M20-Edit', 'totalPremium'),
        // "desc": "总保费",+
        ep: dataPermissions('M20-Edit', 'ep'),
        // "desc": "电子保单",+
        projectRemark: dataPermissions('M20-Edit', 'projectRemark'),
        // "desc": "项目备注",+
        policyPlanList: dataPermissions('M20-Edit', 'policyPlanList'),
        // "desc": "保障计划",+
        policySubjectList: dataPermissions('M20-Edit', 'policySubjectList'),
        // "desc": "标的信息",+
        absoluteDeductible: dataPermissions('M20-Edit', 'absoluteDeductible'),
        // "desc": "扩展不计免赔1",+
        policyInsuredList: dataPermissions('M20-Edit', 'policyInsuredList'),
        // "desc": "被保险人",+
        projectName: dataPermissions('M20-Edit', 'projectName'),
        // "desc": "项目名称",+
        startDate: dataPermissions('M20-Edit', 'startDate'),
        // "desc": "起保时间",+
        enquiryNo: dataPermissions('M20-Edit', 'enquiryNo'),
        // "desc": "询价单号",+
        recorder: dataPermissions('M20-Edit', 'recorder'),
        // "desc": "录单员",
        specialVersion: dataPermissions('M20-Edit', 'specialVersion'),
        // "desc": "客户",
        bindUser: dataPermissions('M20-Edit', 'bindUser'),
      }
    };
  },
  created() {
    //  this.$route.query.id ? this.getDetail() : '';
    if (this.$route.query.id) {
      this.getDetail();
    } else {
      let data = {
        policyNo: '',
        startDate: '',
        endDate: '',
        bindUserAvatar: '',
        bindUserId: '',
        bindUserName: '',
        policyInsuredList: [
          {
            playersName: '',
            contactPhone: ''
          }
        ],
        policySubjectList: [
          {
            frameNo: '',
            plate: '',
            equipmentType: ''
          }
        ],
        attachmentType: 'bdtg',
        attachments: {
          bdtg: []
        },
        customerList: []
      };
      this.policyDetail = JSON.parse(JSON.stringify(data));
      this.originalPolicyDetail = JSON.parse(JSON.stringify(data));
    }
    // this.$route.query.detailData ? this.policyDetail = JSON.parse(this.$route.query.detailData): '';
    // this.$route.query.detailData ? this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail)): '';
    // this.policyDetail.belongTo ? this.policyDetail.customerList = [JSON.parse(this.policyDetail.customerInfo)] : ''
    // this.policyDetail.attachment ? this.policyDetail.attachment = JSON.parse(this.policyDetail.attachment) : '';
    // this.policyDetail.attachment && this.policyDetail.attachment != "{}" ? this.changeAttachment(JSON.parse(this.policyDetail.attachment)) : '';
    this.$forceUpdate();
  },
  methods: {
    changeAttachment(attachment) {
      console.log(attachment);
      if (attachment.length) {
        this.policyDetail.attachmentType = 'bdtg';
        this.policyDetail.attachments.bdtg = attachment.map(item => {
          return {
            attachmentType: 'bdtg',
            fileType: item.suffix,
            url: item.url,
            fileName: item.name
          };
        });
      }
      this.showUpload = false;
      // let dataBackShow = [];
      // let idInd = 1;
      // attachment ? Object.keys(attachment).forEach(function (key) {
      //   let type = (key + '').split('-');
      //   dataBackShow.push({
      //     type: type[0] == 'qt' ? undefined : type[0],
      //     selectValue: Number(type[1]),
      //     urls: attachment[key].map(val => {
      //       idInd++;
      //       return {
      //         suffix: val.fileType,
      //         name: val.fileName,
      //         url: val.url,
      //         id: idInd
      //       };
      //     })
      //   });
      // }) : '';
      // this.dataEcho = dataBackShow;
      // this.$forceUpdate();
    },
    getDetail() {
      getPolicyDetail({
        id: this.$route.query.id
      }).then(res => {
        res.data.customerList = [];
        res.data.customerList = res.data.bindUserId ? [{ userId: res.data.bindUserId, userName: res.data.bindUserName, phoneNum: res.data.bindUserPhone, customerTypeDesc: res.data.customerTypeDesc, referrerName: res.data.referrerName }] : [];
        //this.$set(this.policyDetail, 'customerList', this.policyDetail.bindUserId ? [{ userId: this.policyDetail.bindUserId, userName: this.policyDetail.bindUserName }] : []);
        this.policyDetail = res.data;
        this.originalPolicyDetail = JSON.parse(JSON.stringify(res.data));
        //this.policyDetail.bindUserId ? this.policyDetail.customerList = [{ userId: this.policyDetail.bindUserId, userName: this.policyDetail.bindUserName }] : [];
        this.policyDetail.attachments ? '' : this.policyDetail.attachments = {};
        this.policyDetail.attachments?.bdtg ? '' : this.$set(this.policyDetail.attachments, 'bdtg', []);
        this.$forceUpdate();
      }).catch(err => {
        console.log(err);
      });
    },
    handleBack() {
      this.$router.go(this.$route.query && this.$route.query.isDetails ? -2 : -1);
    },
    async handlePolicyUpdate() {
      let data = await this.$refs.policyForm.handleSubmit();
      trusteeshipSubject({ ...data }).then(res => {
        this.handleBack();
        this.$message.success(`${ this.$route.query && this.$route.query.detailData ? '编辑' : '添加' }成功`);
      });
    },
    handleReset() {
      console.log(this.originalPolicyDetail);
      this.policyDetail = JSON.parse(JSON.stringify(this.originalPolicyDetail));
      // this.$route.query.detailData ? this.policyDetail = JSON.parse(this.$route.query.detailData) : '';
      // this.$route.query.detailData ? this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail)) : '';
      // this.policyDetail.bindUserId ? this.policyDetail.customerList = [JSON.parse(this.policyDetail.customerInfo)] : '';
      // this.policyDetail.attachment ? this.policyDetail.attachment = JSON.parse(this.policyDetail.attachment) : '';
      //this.policyDetail.attachments ? this.changeAttachment(JSON.parse(this.policyDetail.attachments)) : '';
      this.$forceUpdate();
    },
    handleSubmitAttachment(e) {
      // let attachment = {};
      // let attachmentType = [];
      // let qtInd = 1;
      // e.map(val => {
      //   if (val.type) {
      //     attachmentType.push(val.type + '-' + val.selectValue);
      //     attachment[val.type + '-' + val.selectValue] = val.urls.map(item => {
      //       return {
      //         attachmentType: val.type + '-' + val.selectValue,
      //         fileName: item.name,
      //         fileType: item.suffix,
      //         url: item.url
      //       };
      //     });
      //   } else {
      //     attachmentType.push('qt' + qtInd + '-' + val.selectValue);
      //     attachment['qt' + qtInd + '-' + val.selectValue] = val.urls.map(item => {
      //       return {
      //         attachmentType: val.type + '-' + val.selectValue,
      //         fileName: item.name,
      //         fileType: item.suffix,
      //         url: item.url
      //       };
      //     });
      //     qtInd++;
      //   }
      // });
      console.log(e);
      // this.attachments = {
      //   tdfj: JSON.stringify(e)
      // };
      this.showUpload = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: auto;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>