<template>
  <div class="el-collapse-box">
    <el-form :model="ruleForm"
      :rules="rules"
      inline
      ref="ruleForm"
      label-position="top"
      label-width="100px"
      class="el-collapse-box">
      <el-card class="card-item">
        <div class="title">
          基本信息
        </div>
        <div class="divider">
        </div>
        <div class="content">
          <el-form-item label="保单号："
            prop="policyNo"
            ref="policyNo">
            <el-input v-model="ruleForm.policyNo"
              placeholder="请填写"
              maxlength="32"
              @input="input_text($event, ruleForm, 'policyNo')"></el-input>
          </el-form-item>
          <el-form-item label="起保时间："
            prop="startDate">
            <el-date-picker v-model="ruleForm.startDate"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="到保时间："
            prop="endDate">
            <el-date-picker v-model="ruleForm.endDate"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="总保费："
            prop="totalPremium"
            ref="totalPremium">
            <el-input v-model="ruleForm.totalPremium"
              placeholder="请填写"
              maxlength="32"
              @input="input_text($event, ruleForm, 'totalPremium')"></el-input>
          </el-form-item>
          <el-form-item label="总保额："
            prop="totalAmount"
            ref="totalAmount">
            <el-input v-model="ruleForm.totalAmount"
              placeholder="请填写"
              maxlength="32"
              @input="input_text($event, ruleForm, 'totalAmount')"></el-input>
          </el-form-item>
        </div>
      </el-card>
      <el-card class="card-item">
        <div class="title">
          <div style="display: flex;justify-content: space-between;flex: 1;align-items: center;">
            <span>被保险人</span>
            <el-button type="primary"
              size="small"
              icon="el-icon-plus"
              plain
              @click="handleAdd('policyInsuredList', {playersName: '', contactPhone: ''})">添加</el-button>
          </div>
        </div>
        <div class="divider"></div>
        <div class="content"
          v-for="item,index in ruleForm.policyInsuredList"
          :key="index">
          <el-form-item label="被保险人："
            :prop="'policyInsuredList.'+index+'.playersName'"
            ref="insuredName"
            :rules="{ required: true, message: '被保险人不能为空', trigger: 'change' }">
            <el-input v-model="item.playersName"
              placeholder="请填写"
              maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="联系电话："
            prop="phone"
            ref="phone">
            <el-input v-model="item.contactPhone"
              placeholder="请填写"
              maxlength="11"></el-input>
          </el-form-item>
          <el-button type="danger"
            plain
            v-if="index > 0"
            @click="hadnleDel('policyInsuredList', index)">删除</el-button>
        </div>
      </el-card>
      <el-card class="card-item">
        <div class="title">
          <div style="display: flex;justify-content: space-between;flex: 1;align-items: center;">
            <span>标的信息</span>
            <el-button type="primary"
              size="small"
              icon="el-icon-plus"
              plain
              @click="handleAdd('policySubjectList', {equipmentType: '', plate: '', frameNo: ''})">添加</el-button>
          </div>
        </div>
        <div class="divider"></div>
        <div class="content"
          v-for="item,index in ruleForm.policySubjectList"
          :key="index">
          <el-form-item label="设备型号："
            prop="deviceType"
            ref="deviceType">
            <el-input v-model="item.equipmentType"
              placeholder="请填写"
              maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="车牌号："
            prop="plateNo"
            ref="plateNo">
            <el-input v-model="item.plate"
              placeholder="请填写"
              maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="车架号："
            prop="frameNo"
            ref="frameNo">
            <el-input v-model="item.frameNo"
              placeholder="请填写"
              maxlength="32"></el-input>
          </el-form-item>
          <el-button type="danger"
            plain
            v-if="index > 0"
            @click="hadnleDel('policySubjectList', index)">删除</el-button>
          <div class="divider2"
            v-if="ruleForm.policySubjectList.length - 1 > index"></div>
        </div>
      </el-card>
      <el-card class="card-item">
        <div class="title">
          <div style="display: flex;justify-content: space-between;flex: 1;align-items: center;">
            <span>选择客户</span>
            <el-button type="primary"
              size="small"
              v-if="ruleForm.customerList.length"
              plain
              @click="isAllocationCustomer = true">重新选择</el-button>
          </div>
        </div>
        <div class="divider"></div>
        <div class="content">
          <div class="divider2"></div>
          <el-table :data="ruleForm.customerList"
            v-if="ruleForm.customerList.length"
            border>
            <el-table-column label="用户昵称"
              prop="userName"></el-table-column>
            <el-table-column label="手机号"
              prop="phoneNum"></el-table-column>
            <el-table-column label="推荐人"
              prop="referrerName"></el-table-column>
            <el-table-column label="用户类型"
              prop="customerTypeDesc"></el-table-column>
          </el-table>
          <el-button v-else
            type="primary"
            size="small"
            style="display: block;margin: auto;"
            icon="el-icon-plus"
            plain
            @click="isAllocationCustomer = true">添加</el-button>
        </div>
      </el-card>
    </el-form>
    <allocationCustomerDialog @submit="handleBingUser"
      v-model="isAllocationCustomer" />
  </div>
</template>

<script>
import allocationCustomerDialog from "@/views/policyManagement/components/allocationCustomerDialog";
import dayjs from 'dayjs';
export default {
  name: "HostingItemDetail",
  props: {
    detailData: {
      type: Object,
      default: () => { }
    },
  },
  components: { allocationCustomerDialog },
  data() {
    return {
      ruleForm: {
        //   policyNo: '',
        //   startDate: '',
        //   endDate: '',
        //   bindUserId:'',
        //   policyInsuredList: [
        //     { playersName: '', contactPhone: '' }
        //   ],
        //   policySubjectList: [
        //     { equipmentType: '', plate: '', frameNo: '' }
        //   ],
        //   customerInfo: '',
        customerList: [],
        //   belongTo: '',
        //   insuredName: ''
        policyNo: '',
        startDate: '',
        endDate: '',
        bindUserAvatar: '',
        bindUserId: '',
        bindUserName: '',
        policyInsuredList: [
          {
            playersName: '',
            contactPhone: ''
          }
        ],
        policySubjectList: [
          {
            frameNo: '',
            plate: '',
            equipmentType: ''
          }
        ],
        attachmentType: 'bdtg',
        attachments: {
          bdtg: []
        }
      },
      rules: {
        policyNo: [{ required: true, message: '保单号不能为空', trigger: 'blur' }],
        startDate: [{ required: true, message: '起保日期不能为空', trigger: 'blur' }],
        endDate: [{ required: true, message: '到保日期不能为空', trigger: 'blur' }],
        totalPremium: [{ required: true, message: '总保费不能为空', trigger: 'blur' }],
        totalAmount: [{ required: true, message: '总保额不能为空', trigger: 'blur' }],
        //insuredName: [{ required: true, message: '被保险人不能为空', trigger: 'change' }]
      },
      isAllocationCustomer: false
    };
  },
  watch: {
    'ruleForm.policyInsuredList': {
      handler(v) {
        //   let flag = v.findIndex(val => !val.name);
        //   if (flag == -1) {
        //     this.ruleForm.insuredName = '测试';
        //   } else {
        //     this.ruleForm.insuredName = '';
        //   }
      },
      deep: true,
      immediate: true
    },
    detailData: {
      handler(v, o) {
        //   if (v && v.id) {
        //     this.excludePolicyId = v.id + "";
        //     let ruleForm = JSON.parse(JSON.stringify(v));
        //     ruleForm.startDate
        //       ? (ruleForm.startDate = ruleForm.startDate.split(" ")[0])
        //       : "";
        //     ruleForm.endDate
        //       ? (ruleForm.endDate = ruleForm.endDate.split(" ")[0])
        //       : "";
        //     //ruleForm.insuredName = '';
        //     this.ruleForm = ruleForm;
        //   }
        this.ruleForm = v;
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    // 格式化限制数字和字母
    input_text(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和字母以外的字符
      tiem[key] = e.replace(/[^\w]/g, "");
    },
    handleAdd(type, data) {
      this.ruleForm[type].push(data);
    },
    handleSubmit(v) {
      return new Promise((resolve, reject) => {
        this.$refs["ruleForm"].validate((valid, validObj) => {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
          //ruleForm.policySubjectList = ruleForm.policySubjectList.filter(val => val.equipmentType || val.plate || val.frameNo);
          ruleForm.startDate ? ruleForm.startDate = dayjs(ruleForm.startDate).format('YYYY-MM-DD 00:00:00') : '';
          ruleForm.endDate ? ruleForm.endDate = dayjs(ruleForm.endDate).format('YYYY-MM-DD 23:59:59') : '';
          if (new Date(ruleForm.startDate) > new Date(ruleForm.endDate)) {
            this.$message.error('起保时间不能大于到保时间');
            reject("校验未通过");
            return false;
          }
          if (valid && ruleForm.bindUserId) {
            resolve(ruleForm);
          } else if (!ruleForm.bindUserId) {
            this.$message.error('请选择客户');
            reject("校验未通过");
          } else {
            reject("校验未通过");
          }
        });
      });
    },
    handleBingUser(e) {
      this.ruleForm.customerInfo = JSON.stringify(e);
      this.ruleForm.customerList = [JSON.parse(JSON.stringify(e))];
      this.ruleForm.bindUserId = e.userId;
      this.ruleForm.bindUserName = e.userName;
      this.ruleForm.bindUserPhone = e.phoneNum;
      this.ruleForm.referrerName = e.referrerName;
      this.ruleForm.customerTypeDesc = e.customerTypeDesc;
      this.isAllocationCustomer = false;
    },
    hadnleDel(type, index) {
      this.ruleForm[type].splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-item {
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: column;
  margin-top: 10px;

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 0px;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 24px;
      background: #4278c9;
      border-radius: 20px 4px 4px 20px;
      opacity: 1;
      margin: 0 8px 0 4px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 12px 4px 0 4px;
    background-image: linear-gradient(
      to right,
      #ccc 0%,
      #ccc 50%,
      transparent 50%
    );
    background-size: 15px 1px;
    background-repeat: repeat-x;
  }

  .divider2 {
    width: 100%;
    height: 20px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .content-item {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 24px 0 0 18px;
      // min-width: 300px;
    }
    ::v-deep .el-form-item {
      width: 30%;
      min-width: 300px;
    }
  }
}

.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
</style>
